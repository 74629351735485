.title{
    text-align: center;
}
.wwa{
    color: black;
    
}

.wwatext{
    align-self: flex-start;
    justify-self: flex-start;
    text-align: left;
}
.cmage{
    height: 200px;
}

@media only screen and (min-width: 600px) {
    .carousel {
        display: none;

    }
    .images{
        height: 16em;
    }
  }
  @media only screen and (max-width: 600px) {
    .image {
      display: none;
    }

   
  }
  .pic1{
      margin-top: -50px;
  }
  .dd{
    margin-top: 50px;
  }
  .yen{
      margin-top: -50px;
  }

  .imgs{
      margin-bottom: 10px;
    width: 15em;
    height: 10em;
    margin-left: 10px;
 }
 
.ppl{
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-around;
    width: 900px;
    margin-top: 5%;
}
.one{
    display: grid;
   align-content: center;
   background-color:  #67D2D6;
   width: 200px;
   height: 200px;
   border-radius: 50%;
   }
.two{
    display: grid;
    align-content: center;
    background-color:  #E6709C;
    width: 200px;
    height: 200px;
    border-radius: 50%;
}
.three{
    display: grid;
    align-content: center;
    background-color: #F2C339;
    width: 200px;
    height: 200px;
    border-radius: 50%;
}
.Program{
    text-align: center;
    margin-top: 10%;
}
.cd{
    height: 60vh;
}