.Header{
    background-color: #E6709C;
    color: #FFFFFF;
    text-align: center;

    /* position: fixed; */
    width: 100%;
}
.others{
    justify-content: end;
}
.head{
    background-color: #E6709C;
}

.nav-link{
    text-decoration: none!important;
    color: #FFFFFF;
    }
.nav-link:hover{
    background-color: #67D2D6;
    border-radius: 5px;
}
.navbar-brand{
    color: #FFFFFF;
}
